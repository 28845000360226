<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Договор лизинга {{ $route.params.id }}</div>
        <div class="hr"></div>


        <div v-if="error" class="position-relative no-list-data">
            <ui-no-data-page :text="error" />
        </div>

        <div v-else>

            <div class="search-filter mt-4">
                <div class="search-filter__item">
                    <RouterLink class="btn btn-ghost btn-back" to="/contracts">
                        <LeftIcon class="icon" />
                    </RouterLink>
                </div>
                <div class="search-filter__item">
                    <ui-button :white="true" @click="setPage(null)" class="search-filter__link"
                        :class="!page && 'active'">Информация</ui-button>
                </div>
                <div class="search-filter__item">
                    <ui-button :white="true" @click="setPage('graph')" class="search-filter__link"
                        :class="page === 'graph' && 'active'">График платежей</ui-button>
                </div>

                <div v-if="page === 'graph'" class="search-filter__item">


                    <ui-button @click="openList" class="btn-ghost btn-select" :loading="loadingGraphPage">


                        <div class="row">
                            <div class="col px-0">
                                <span v-if="graphPage === 'leasing'">График лизинговых платежей&nbsp;</span>
                                <span v-else-if="graphPage === 'red'">График выкупных платежей&nbsp;</span>
                                <span v-else>График порядка расчетов&nbsp;</span>
                            </div>
                            <div class="col-auto text-end pe-0">
                                <UpIcon v-if="listShow" class="icon" />
                                <DownIcon v-else class="icon" />
                            </div>
                        </div>

                    </ui-button>

                    <div v-if="listShow" class="scroll select-menu">
                        <ul class="select-menu-list">
                            <li @click="setGraphPage(null)" class="select-menu-item">
                                График порядка расчетов
                            </li>
                            <li v-if="graphs.lp.palan.length !== 0" @click="setGraphPage('leasing')"
                                class="select-menu-item">
                                График лизинговых платежей
                            </li>
                            <li v-if="graphs.v.palan.length !== 0" @click="setGraphPage('red')" class="select-menu-item">
                                График выкупных платежей
                            </li>
                        </ul>
                    </div>

                    <div v-if="listShow" @click="openList" class="bg-modal"></div>

                </div>

            </div>

            <div v-if="page === 'graph'">

                <div v-if="graphPage === 'leasing'">
                    <!-- <div class="page-title">График лизинговых платежей</div>
                    <div class="hr"></div> -->

                    <div class="scroll table-responsive">
                        <table style="width: 100%;">
                            <tr>
                                <td style="vertical-align: top;width: 50%;">

                                    <div class="page-title">План</div>
                                    <div class="hr mb-4"></div>

                                    <div class="scroll table-responsive">
                                        <table class="table text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Дата</th>
                                                    <th>Сумма</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item, index in graphs.lp.palan">
                                                    <td>
                                                        {{ dateMask(item['Дата']) }}
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(item['Значение']) }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <b>Итого</b>
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(String(graphs.lp.palan.reduce((accumulator,
                                                            currentValue) => {
                                                            return (accumulator ? accumulator : 0) +
                                                                Number(currentValue['Значение'])
                                                        }, sum))) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td style="vertical-align: top;width: 50%;">

                                    <div class="page-title">Факт</div>
                                    <div class="hr mb-4"></div>

                                    <div class="scroll table-responsive">
                                        <table class="table text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Дата</th>
                                                    <th>Сумма</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item, index in graphs.lp.fact">
                                                    <td>
                                                        {{ dateMask(item['Дата']) }}
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(item['Значение']) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Итого на сегодня</b>
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(String(graphs.lp.fact.reduce((accumulator,
                                                            currentValue) => {
                                                            return (accumulator ? accumulator : 0) +
                                                                Number(currentValue['Значение'])
                                                        }, sum))) }}
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>

                <div v-else-if="graphPage === 'red'">
                    <!-- <div class="page-title">График выкупных платежей</div>
                    <div class="hr"></div> -->


                    <div class="scroll table-responsive" v-if="graphs.v.palan.length !== 0">
                        <table style="width: 100%;">
                            <tr>
                                <td style="vertical-align: top;width: 50%;">

                                    <div class="page-title">План</div>
                                    <div class="hr mb-4"></div>

                                    <div class="scroll table-responsive">
                                        <table class="table text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Дата</th>
                                                    <th>Сумма</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item, index in graphs.v.palan">
                                                    <td>
                                                        {{ dateMask(item['Дата']) }}
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(item['Значение']) }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <b>Итого</b>
                                                    </td>
                                                    <td>
                                                        <span v-if="graphs.v.palan.length !== 0">
                                                            {{ moneyMask(String(graphs.v.palan.reduce((accumulator,
                                                                currentValue) => {
                                                                return (accumulator ? accumulator : 0) +
                                                                    Number(currentValue['Значение'])
                                                            }, sum))) }}
                                                        </span>
                                                        <span v-else>0</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td style="vertical-align: top;width: 50%;">

                                    <div class="page-title">Факт</div>
                                    <div class="hr mb-4"></div>

                                    <div class="scroll table-responsive">
                                        <table class="table text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Дата</th>
                                                    <th>Сумма</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item, index in graphs.v.fact">
                                                    <td>
                                                        {{ dateMask(item['Дата']) }}
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(item['Значение']) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Итого на сегодня</b>
                                                    </td>
                                                    <td>

                                                        <span v-if="graphs.v.fact.length !== 0">
                                                            {{ moneyMask(String(graphs.v.fact.reduce((accumulator,
                                                                currentValue) => {
                                                                return (accumulator ? accumulator : 0) +
                                                                    Number(currentValue['Значение'])
                                                            }, sum))) }}
                                                        </span>
                                                        <span v-else>Нет платежей</span>

                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>


                </div>

                <div v-else>
                    <!-- <div class="page-title">График порядка расчетов</div>
                    <div class="hr"></div> -->

                    <div class="scroll table-responsive">
                        <table style="width: 100%;">
                            <tr>
                                <td style="vertical-align: top;width: 50%;">
                                    <!-- <h2>План</h2> -->

                                    <div class="page-title">План</div>
                                    <div class="hr mb-4"></div>

                                    <div class="scroll table-responsive">
                                        <table class="table text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Дата</th>
                                                    <th>Сумма</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item, index in graphs.pr.palan">
                                                    <td>
                                                        {{ dateMask(item['Дата']) }}
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(item['Значение']) }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <b>Итого</b>
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(String(graphs.pr.palan.reduce((accumulator,
                                                            currentValue) => {
                                                            return (accumulator ? accumulator : 0) +
                                                                Number(currentValue['Значение'])
                                                        }, sum))) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td style="vertical-align: top;width: 50%;">
                                    <!-- <h2>Факт</h2> -->

                                    <div class="page-title">Факт</div>
                                    <div class="hr mb-4"></div>

                                    <div class="scroll table-responsive">
                                        <table class="table text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Дата</th>
                                                    <th>Сумма</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item, index in graphs.pr.fact">
                                                    <td>
                                                        {{ dateMask(item['Дата']) }}
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(item['Значение']) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Итого на сегодня</b>
                                                    </td>
                                                    <td>
                                                        {{ moneyMask(String(graphs.pr.fact.reduce((accumulator,
                                                            currentValue) => {
                                                            return (accumulator ? accumulator : 0) +
                                                                Number(currentValue['Значение'])
                                                        }, sum))) }}
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>


            </div>

            <div v-else>


                <div v-if="details.length !== 0">


                    <div class="scroll table-responsive mt-4 mb-4">
                        <table class="table text-nowrap mb-0">
                            <tbody>
                                <tr>
                                    <td>
                                        Балансодержатель
                                    </td>
                                    <td colspan="3">
                                        {{ bald }}
                                    </td>
                                </tr>
                                <tr v-for="item, index in details">
                                    <td>
                                        {{ item.type.charAt(0).toUpperCase() + item.type.slice(1) }}
                                    </td>
                                    <td>
                                        {{ item.brand }}
                                    </td>
                                    <td>
                                        Регистрация в ГИБДД: {{ item.r }}
                                    </td>
                                    <td>
                                        Следующий ГТО: {{ dateMask(item.dateTo) }}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>


                <div v-if="getCompany.mvz === '1'">

                    <div class="page-title">МВЗ</div>
                    <div class="hr"></div>

                    <div v-if="Object.keys(mvzSendErrors).length !== 0" class="login-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ mvzSendErrors[Object.keys(mvzSendErrors)[0]] }}</span>
                    </div>

                    <div v-if="mvzSuccess" class="success-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ mvzSuccess }}</span>
                    </div>


                    <div class="scroll table-responsive mt-4">
                        <table class="table text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>МВЗ</th>
                                    <th>ФИО</th>
                                    <th>Email</th>
                                    <th>Телефон</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ mvz.mvz ? mvz.mvz : 'Не указан' }}</td>
                                    <td>{{ mvz.fio ? mvz.fio : 'Не указан' }}</td>
                                    <td>{{ mvz.email ? mvz.email : 'Не указан' }}</td>
                                    <td>{{ mvz.phone ? mvz.phone : 'Не указан' }}</td>
                                    <td class="text-end">
                                        <ui-button @click="modalMvzOpenClose" class="btn-ghost btn-small">
                                            Редактировать
                                        </ui-button>&nbsp;
                                        <ui-button @click="mvzSend" :loading="mvzSendLoading" :disabled="mvzSendLoading"
                                            class="btn-base btn-small">Выслать
                                            приглашение</ui-button>
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>

                </div>


                <div class="page-title">Архив документов</div>
                <div class="hr"></div>



                <div v-if="fsbu.length !== 0">



                    <div class="scroll table-responsive mt-4">
                        <table class="table text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th colspan="2">ФСБУ</th>
                                </tr>
                            </thead>
                            <tbody>

                                <template v-for="item, index in fsbu">
                                    <tr v-if="item.example === '1'">


                                        <td>
                                            Пример учета ЛС № {{ $route.params.id }} по ФСБУ 25/2018
                                        </td>

                                        <td class="text-end">
                                            <ui-button @click="downloadFsbu(index, 'example', item.example, item.number)"
                                                :progress="item.progressE ? item.progressE : 0" :loading="item.loadingE"
                                                class="btn-base btn-small">Скачать</ui-button>
                                        </td>

                                    </tr>
                                    <tr v-else-if="item.example">
                                        <td>
                                            Пример учета ЛС № {{ $route.params.id }} по ФСБУ 25/2018. Инв.№ {{ item.example
                                            }}
                                        </td>


                                        <td class="text-end">
                                            <ui-button @click="downloadFsbu(index, 'example', item.example, item.number)"
                                                :progress="item.progressE ? item.progressE : 0" :loading="item.loadingE"
                                                class="btn-base btn-small">Скачать</ui-button>
                                        </td>

                                    </tr>


                                    <tr v-if="item.letter === '1'">

                                        <td>
                                            Информация по учету
                                        </td>

                                        <td class="text-end">
                                            <ui-button @click="downloadFsbu(index, 'letter', item.letter, item.number)"
                                                :progress="item.progressL ? item.progressL : 0" :loading="item.loadingL"
                                                class="btn-base btn-small">Скачать</ui-button>
                                        </td>

                                    </tr>

                                    <tr v-else-if="item.letter">

                                        <td>
                                            Информация по учету. Инв.№ {{ item.letter }}
                                        </td>


                                        <td class="text-end">
                                            <ui-button @click="downloadFsbu(index, 'letter', item.letter, item.number)"
                                                :progress="item.progressL ? item.progressL : 0" :loading="item.loadingL"
                                                class="btn-base btn-small">Скачать</ui-button>
                                        </td>

                                    </tr>

                                </template>

                            </tbody>
                        </table>
                    </div>

                </div>


                <div v-if="archive.length !== 0">


                    <div class="scroll table-responsive mt-4">
                        <table class="table text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th colspan="4">Архив документов по договору {{ $route.params.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item, index in archive">
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.id }}
                                    </td>
                                    <td>
                                        {{ dateMask(item.date) }}
                                    </td>
                                    <td class="text-end">
                                        <ui-button @click="download(index, 'da', item['Код'])"
                                            :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                            class="btn-base btn-small">Скачать</ui-button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>

            </div>



        </div>

    </div>

    <ui-modal v-if="modalMvz" :close="modalMvzOpenClose">
        <div class="modal-header">
            <h5 class="modal-title">Редактировать МВЗ</h5>
            <button class="btn-close" type="button" @click="modalMvzOpenClose"></button>
        </div>
        <div class="modal-body">
            <ui-input-box-floating class="mt-3" :error="mvzErrors.mvz">
                <ui-input v-model="mvzText" class="form-control" placeholder="МВЗ" />
                <ui-input-label>МВЗ</ui-input-label>
            </ui-input-box-floating>

            <ui-input-box-floating class="mt-3" :error="mvzErrors.fio">
                <ui-input v-model="mvzFIO" class="form-control" placeholder="ФИО" />
                <ui-input-label>ФИО</ui-input-label>
            </ui-input-box-floating>

            <ui-input-box-floating class="mt-3" :error="mvzErrors.email">
                <ui-input v-model="mvzEmail" class="form-control" placeholder="Email" />
                <ui-input-label>Email</ui-input-label>
            </ui-input-box-floating>

            <ui-input-box-floating class="mt-3" :error="mvzErrors.phone">
                <ui-input v-model="mvzPhone" class="form-control" v-maska data-maska="+7 (###) ###-##-##"
                    placeholder="Телефон" />
                <ui-input-label>Телефон</ui-input-label>
            </ui-input-box-floating>

            <div v-if="mvzError" class="login-alert p-3 text-center mt-3">
                <div class="text-primary">Внимание!</div>
                <span>{{ mvzError }}</span>
            </div>

            <ui-button @click="mvzSave" :loading="mvzLoading" :disabled="mvzLoading"
                class="btn-base mt-3 me-2">Сохранить</ui-button>
        </div>
        <div class="modal-footer"></div>
    </ui-modal>

    <ui-modal v-if="modal.msg" :close="modalClose">
        <div class="modal-header">
            <h5 class="modal-title">{{ modal.title }}</h5>
            <button class="btn-close" type="button" @click="modalClose"></button>
        </div>
        <div class="modal-body">
            {{ modal.msg }}
        </div>
        <div class="modal-footer"></div>
    </ui-modal>
</template>


<script>
import { getApi, postApi, dateMask, moneyMask, downloadApi } from '@/run'
import { mapGetters } from "vuex"

import LeftIcon from "@/assets/svg/left.vue"

import DownIcon from "@/assets/svg/down.vue"
import UpIcon from "@/assets/svg/up.vue"
import PenIcon from "@/assets/svg/pen.vue"

export default {
    name: 'contracts.contract',
    data() {
        return {
            loading: false,
            loadingGraphPage: false,
            error: null,
            page: null,
            graphPage: null,
            listShow: false,
            archive: [],
            fsbu: [],
            details: [],
            graphs: [],
            mvz: {},
            mvzText: null,
            mvzFIO: null,
            mvzEmail: null,
            mvzPhone: null,
            mvzError: null,
            mvzErrors: {},
            mvzSendError: null,
            mvzSendErrors: {},
            mvzSendLoading: false,
            bald: '',
            dateMask: dateMask,
            moneyMask: moneyMask,
            modal: {},
            modalMvz: false,
            mvzLoading: false,
            mvzSuccess: false
        }
    },
    components: {
        LeftIcon,
        DownIcon,
        UpIcon,
        PenIcon
    },
    created() {
        document.title = "Договор лизинга " + this.$route.params.id + " - STONE-XXI"

        this.start()
    },
    computed: {
        ...mapGetters({
            getCompany: 'main/getCompany'
        })
    },
    methods: {
        start() {

            this.loading = true

            getApi('/contract', {
                id: this.$route.params.id
            }).then(response => {

                if (response.status) {
                    this.archive = response.archive
                    this.fsbu = response.fsbu
                    this.details = response.details
                    this.mvz = response.mvz
                    this.bald = response.bald,
                        this.graphs = response.graphs
                }

                if (response.error) {
                    this.error = response.error
                }

                this.loading = false

            })

        },
        setPage(page) {
            this.page = page
        },
        setGraphPage(page) {
            this.loadingGraphPage = true
            this.listShow = !this.listShow
            setTimeout(() => {
                this.graphPage = page
                this.loadingGraphPage = false
            }, 300)
        },
        openList() {
            this.listShow = !this.listShow
        },
        progress(btn, progress) {
            this.archive = this.archive.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        download(btn, type, file) {

            this.archive = this.archive.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })

            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progress).then(response => {

                this.archive = this.archive.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        progressFsbu(btn, progress) {
            this.fsbu = this.fsbu.map((item, i) => i === btn ? item.loadingE ? { ...item, progressE: progress } : { ...item, progressL: progress } : { ...item })
        },
        downloadFsbu(btn, type, file, number) {



            if (type === 'example') {
                this.fsbu = this.fsbu.map((item, i) => i === btn ? { ...item, loadingE: true } : { ...item })
            } else if (type === 'letter') {
                this.fsbu = this.fsbu.map((item, i) => i === btn ? { ...item, loadingL: true } : { ...item })
            }

            downloadApi('/download', {
                type: type,
                file: file,
                number: number
            }, btn, this.progressFsbu).then(response => {


                if (type === 'example') {
                    this.fsbu = this.fsbu.map((item, i) => i === btn ? { ...item, progressE: false, loadingE: false } : { ...item })
                } else if (type === 'letter') {
                    this.fsbu = this.fsbu.map((item, i) => i === btn ? { ...item, progressL: false, loadingL: false } : { ...item })
                }

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        mvzSend() {
            this.mvzSuccess = false
            this.mvzSendError = null
            this.mvzSendErrors = {}
            this.mvzSendLoading = true

            postApi('/contract/mvzSend', this.mvz).then(response => {

                if (response.error) {
                    this.mvzSendError = response.error
                    this.mvzSendErrors = response.errors
                } else {
                    this.mvzSuccess = response.success
                }

                this.mvzSendLoading = false

            })

        },
        mvzSave() {

            this.mvzError = null
            this.mvzLoading = true

            postApi('/contract/mvzSave', {
                id: this.$route.params.id,
                mvz: this.mvzText,
                fio: this.mvzFIO,
                email: this.mvzEmail,
                phone: this.mvzPhone
            }).then(response => {

                if (response.error) {
                    this.mvzError = response.error
                    this.mvzErrors = response.errors
                } else {
                    this.mvz = {
                        ...this.mvz,
                        mvz: this.mvzText,
                        fio: this.mvzFIO,
                        email: this.mvzEmail,
                        phone: this.mvzPhone
                    }
                    this.modalMvzOpenClose()
                }

                this.mvzLoading = false

            })
        },
        modalClose() {
            this.modal = {}
        },
        modalMvzOpenClose() {
            this.mvzError = null
            this.mvzErrors = {}
            this.modalMvz = !this.modalMvz

            if (this.modalMvz) {
                this.mvzText = this.mvz.mvz
                this.mvzFIO = this.mvz.fio
                this.mvzEmail = this.mvz.email
                this.mvzPhone = this.mvz.phone
            }
        }
    }
}
</script>